import React from 'react'
import Layout from '@components/Layout'
import PageTitle from '@components/PageTitle'
import { TitleParagraph } from '@global'

const Gallery = () => (
  <Layout>
    <PageTitle title="Gallery" />
    <TitleParagraph>More Information Will Be Available Soon</TitleParagraph>
  </Layout>
)

export default Gallery
